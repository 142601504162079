<template>
  <div class="production-detail">
    <headNav active="2"></headNav>
    <div class="contents tal w1200">
      <h2 class="fs40 cmain">{{titleText}}{{$t('producDeta.commission')}}</h2>
      <p class="fs16 cmain tips">
        {{$t('producDeta.title')}}
      </p>

      <div class="item flex between" v-for="(item,key) in contents" :key="key">
        <div class="flex between" v-if="key%2 == 0">
          <div class="text">
            <h3 class="fs24" v-if="$i18n.locale == 'zh'">{{item.title1}}</h3>
            <h3 class="fs24" v-else>{{item.title2}}</h3>
            <p class="fs16" v-if="$i18n.locale == 'zh'">{{item.content1}}</p>
            <p class="fs16" v-else>{{item.content2}}</p>
          </div>
          <div class="pic ml-60">
            <img :src="require(`../assets/production/${item.url}.png`)" alt="" />
          </div>
        </div>
        <div class="flex between" v-else>
          <div class="pic mr-60">
            <img :src="require(`../assets/production/${item.url}.png`)" alt="" />
          </div>
          <div class="text">
            <h3 class="fs24" v-if="$i18n.locale == 'zh'">{{item.title1}}</h3>
            <h3 class="fs24" v-else>{{item.title2}}</h3>
            <p class="fs16" v-if="$i18n.locale == 'zh'">{{item.content1}}</p>
            <p class="fs16" v-else>{{item.content2}}</p>
          </div>      
        </div>
       
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
const dataSoure = {
  "1": [
    {
      url: 'zhaojiping',
      title1: "赵季平 - 《风雅颂之交响》",
      title2: "Zhao Jiping - “Symphony of Feng-Ya-Song”",
      content1:
        "2018年赵季平先生为苏州民族管弦乐团创作作品《风雅颂之交响》。该作品以中华传统文化之诗歌滥觞《诗经》为创作宗旨，选取其中有代表性的章节，作曲家用独特的音乐语言向中华传统经典致敬，讴歌祖国、讴歌时代、讴歌人民、讴歌英雄，充分展现了领航新时代泱泱大国的形象和魅力。作品风格雄壮、典雅，共有《序·颂》、《关雎》、《玄鸟》、《幽兰操》、《国风》五个乐章。",
      content2:
        "In 2018, Mr. Zhao composed the “Symphony of Feng-Ya-Song” for the Suzhou Chinese Orchestra. Inspired by the “Classic of Poetry,” the composer had selected the prominent chapters of the collection and with his unique musical language, the work pays tribute to the classic of Zhonghua traditions, eulogizes the motherland, the era, the people, and the heroes, emitting the charm of a large country that leads the new era.",
    },
    {
      url: 'liuchangyuan',
      title1: "刘长远 - 《丝竹的交响》",
      title2: "Liu Changyuan - “Symphony of Sizhu”",
      content1:
        "刘长远是中央音乐学院作曲教授，硕士生导师，民族管弦乐学会创作委员会副主任。2018年苏州民族管弦乐团委约刘长远先生创作民族管弦乐作品《丝竹的交响》。该作品音乐材料取自江苏民歌《姑苏风光》，分两个乐章，仿佛让我们听到了太湖的船歌、寒山寺的钟声、庭院中的评弹和水乡的浪花，抒发了作者对江南水乡的赞美，以及苏州人民深爱故乡之情感，体现了中华儿女强烈的民族自豪感",
      content2:"Mr. Liu Changyuan is a professor at the Department of Composition at Central Conservatory of Music, master’s supervisor, and vice president of the Chinese Orchestra Composition Committee. In 2018, the Suzhou Chinese Orchestra commissioned Mr. Liu to compose the Chinese Symphony “Symphony of Sizhu”. The material of the work was extracted from the Jiangsu folk song “Beautiful Scenery of Gusu.” Divided into two movements, the music let us hear the Taihu Lake’s boat song, bells of Hanshan Temple, Pingtan in the courtyard, and waves of the water village. The work expresses the composer’s praise towards the water village of Jiangnan and the Suzhou people’s love for the hometown, presenting a strong sense of national pride of the Zhonghua people."
    },
    {
      url: 'libinyang',
      title1: "李滨扬 -《烟雨枫桥》",
      title2: "Li Binyang - “Misty Rain on Maple Bridge”",
      content1:
        "李滨扬是中央音乐学院作曲系教授，博士生导师。2018年苏州民族管弦乐团委约李滨扬先生创作民族管弦乐作品《烟雨枫桥》。该作品曲名取自杜牧（唐）“暮烟疏雨过枫桥”。在隐约的苏州评弹与昆曲元素的穿引下，通过时而飘渺、时而清雅、时而梦幻、时而浪漫激越的音乐历程，描绘出作者心中那座令人神往、朦胧色彩中的千年枫桥，体现出作者爱家乡、爱祖国的美好情怀。",
      content2:"Mr. Li Binyang is a professor at the Department of Composition of Central Conservatory of Music, doctorate’s supervisor. In 2018, the Suzhou Chinese Orchestra commissioned Mr. Li to compose the Chinese Symphony “Misty Rain on Maple Bridge.” The title of the piece was inspired by the poem line “Twilight fog and light rain over the maple bridge.” Vaguely lead by the elements of Suzhou Pingtan and Kunqu, through the music journey in the mixture of faintness, elegance, dreamy and romance with aggression, the work depicts the composer’s imagination of the fascinating millennial maple bridge in hazy colors, showing his love for the hometown and the motherland."
    },
    {
      url: 'zhangzhao',
      title1: "张朝 - 《干将莫邪幻想曲》",
      title2: "Zhang Zhao - “Fantasia of Gan Jiang Mo Ye”",
      content1:
        "张朝是中国音乐家协会会员、中央民族大学音乐学院作曲教授、硕士研究生导师。2018年苏州民族管弦乐团委约张朝先生创作民族管弦乐作品《干将·莫邪幻想曲》。该作品取自苏州民间传说“干将莫邪”的感人故事，乐曲分为誓言、山雪、烈火、涅槃四个乐章。作品避开文学的叙事，突出情怀与心灵的表达，通过四个乐章的演奏，向观众传达了大爱、大义、大勇的精神力量，歌颂了人民、歌颂了英雄。",
      content2: "Mr. Zhang Zhao is a member of the Chinese Musicians’ Association, a professor of the Music School of Minzu University of China, and master’s supervisor. In 2018, the Suzhou Chinese Orchestra commissioned Mr. Zhang to compose the Chinese Symphony “Fantasia of Gan Jiang Mo Ye.” The work was inspired by the touching story of the Suzhou folk legend “Gan Jiang Mo Ye.” Divided into four parts, namely: The Oath, Mountain’s Snow, Inferno, and Nirvana, the work avoids literary narration and emphasizes the expression of the feelings and spirit. The music delivers the spiritual energy of great love, great righteousness and great courage to the audience, and eulogizes the people and the heroes."
    },
  ],
  "2": [
    {
      url: 'haoweiya',
      title1: "郝维亚 - 《来自苏州的声音》",
      title2: "Hao Weiya - “The Sounds from Suzhou”",
      content1:
        "该作品源于作曲家对苏州这个城市的热爱。今天，我们生活的环境里，已经很少有像苏州这样令人感慨不已的城市，既保持古色古韵的味道，又兼顾时代发展的需求。作品拟分三个乐章，分别从苏州的“园林”“山水”“昆曲”中汲取素材，并考虑以当代人的视角重新感受和审视苏州的独特气息，展现了苏州经过四十年改革开放的洗礼所取得的发展成就，歌颂了党的英明领导和苏州人民的勤劳与智慧！",
      content2:"The work was inspired by the composer’s love for the city of Suzhou. Today, it’s very fortunate for us to live in an awesome city like Suzhou which preserves the ancient taste but also meets the need of the development in the era. The work is divided into three movements, with the materials extracted from the “Garden”, “Land” and “Kunqu” of Suzhou respectively, considering the angle of view of the modern people and observing the unique features of Suzhou, it showcases the developmental achievements of Suzhou after 40 years of Reform and Opening-up, eulogizes the wise leadership of the Party and the hard work and wisdom of the people!"
    },
    {
      url: 'jiangying',
      title1: "姜莹 - 《艰难与辉煌》",
      title2: "Jiang Ying - “Hardship and Glory”",
      content1:
        "20世纪的中华民族经历了百年沧桑，是革命先烈对实现共产主义的理想信念，一路艰难走来。乐曲该作品以叙述性的创作方式展开，主题动机运用了《国际歌》的素材贯穿始终，使音乐具有强烈的英雄主义气质。我们缅怀英雄，是他们用热血和信仰铸就了革命的伟大胜利。这是一部用民族管弦乐交响化的理念来创作的作品，体现了波澜壮阔的革命历史史诗。今天，我们不忘初心，牢记使命，为中华民族崭新的时代谱写辉煌的乐章。此曲献给建党100周年！",
      content2:"The people of Zhonghua had gone through a hundred years of vicissitudes in the 20th century. It was the ideal and belief of revolutionary martyrs to realize communism that brought about China today. The work exposes via a narrative manner and the theme is adopted from “L’Internationale), which radiates a strong sense of heroism. We remember the heroes, it is they who achieved the great success of the revolution with their passion and faith. This is a work that aims to symphonize the Chinese traditional orchestra and to present the epic history of the revolution. Today, we stay true to our original aspiration and founding mission and compose the glorious movements for the new era of Zhonghua people. This work is a tribute to the 100th Anniversary of the Founding of CPC."
    },
    {
      url: 'libinyang',
      title1: "李滨扬 - 《吴越春秋》",
      title2: "Li Binyang - “Wu Yue Eras”",
      content1:
        "该作品以优美的曲调与富有内涵的意境，主要描写吴越大地在春秋时期的重大历史事件与丰富的文化成就，歌颂了祖国悠久灿烂的历史文化，揉发了爱国情怀！",
      content2:`The history of Wu Yue Eras was like a giant scroll painting, a thrilling saga. Observing the history, many of the familiar historical figures, stories and allusions occurred in the eras. The music attempts to use symphonic techniques from the composer's own understanding and perspective to connect and display the representative historical facts and stories in the thrilling Wu Yue epic. The work uses part of Kunqu tune as the original material. The whole symphony is composed of five parts and is performed continuously: "Wu and Yue Strive for Hegemony", "Endurance of Hardship", "Sword of the Yue Maiden", "Resurgence of War" and "Behold Jiangnan".`
    },
    {
      url: 'guowenjing',
      title1: "郭文景 - 《桃花庵》",
      title2: "Guo Wenjing - “Peach Blossom Cottage”",
      content1:
        "该作品取自明代画家、文学家、诗人唐寅创作的一首七言古诗《桃花庵歌》，主要表达了诗人乐于归隐、淡泊功名、不愿与世俗交接追求闲适的生活态度，乐曲以绵延的艺术张力，歌颂了古人追求自由、珍视个体生命价值的可贵精神与不和世俗同流的刚正风骨！",
      content2:"The work was inspired by the seven-sentence ancient poem “Song of the Peach Blossom Cottage” written by Tang Yin (painter, writer, and poet of Ming Dynasty). It mainly expresses the poet’s life attitude that he is willing to live away from the secular world and indifferent to fame. With the constant tension of the music, the work eulogizes the valuable spirit and integrity of the ancient people who pursued freedom and cherished the value of life."
    },
  ],
  "3": [
    {
      url: 'tangjianping',
      title1: "唐建平《江河湖海颂》",
      title2: "Tang Jianping - “Ode to Rivers, Lakes and the Sea”",
      content1: "江苏，地处中国的东部，山清水秀，人文荟萃，江河湖海在这里汇集，历史文脉在这里交聚。这里有中国当今社会发展最典型意义的“苏南模式”、“昆山之路”和“张家港精神”，成为新中国建立70多年、中国共产党建党100年来，中国现代历史发展成就的见证。乐曲就是讴歌了这片土地上的人文精神、风貌神韵和风土人情。",
      content2:"Jiangsu people have a mind as broad as the rivers, lakes and the sea since ancient times. The work eulogizes the water-cultivated Jiangsu’s humanism and nature, the great journey and history of the building of CPC, the transformation on the land of China since hundred years ago, and the great achievements until the 100th Anniversary of the Founding of CPC."
    },
    {
      url: 'xuchangjun',
      title1: "徐昌俊《虎丘的传说》",
      title2: "Xu Changjun - “The Legend of Huqiu”",
      content1: "宋代大诗人苏东坡写下“尝言过姑苏不游虎丘，不谒闾丘，乃二欠事”的千古名言。该作品以虎丘各种历史典故与美丽传说为素材，创设穿越时空的美妙意境，歌颂中华民族悠久而灿烂的历史文化！",
      content2:"The great poet of Song Dynasty - Su Dongpo wrote the famous phrase: “Coming to Suzhou, it’ll be a regret if I don’t go to Huqiu and pay a visit to my friend Lv Qiu”. The work refers to the history and legend that occurred in Huqiu, traveling in time and space, it eulogizes the brilliant historical culture of the Zhonghua people."
    },
    {
      url: 'tangjianping',
      title1: "唐建平《苏风三月》",
      title2: "Tang Jianping “The Suzhou Wind in March”",
      content1: "该作品描绘了姑苏大地春天时分的自然景象，欢快悠扬，甜美抒情，使人仿佛置身于幽曲明净、精巧秀丽的姑苏园林和春光明媚的江南原野之中，展示了江南三月，万物争春，一派人间天堂春意盎然的美景！象征在党的领导下，国家各项事业蒸蒸日上、人民生活幸福安康的盛世景象！",
      content2:"The work depicts the nature view of the Suzhou in spring season. Set in a happy mood, sweet and sentimental, the music brings the audience into a beautiful garden and bright wilderness in Jiangnan in spring season, presenting the March in Jiangnan, all living trees and animals are vibrant and full of energy, symbolizing that under the leadership of the Party, the country develops fast in various fields, and the people are living healthily in prosperity!"
    },
    {
      url: 'zhangzhao',
      title1: "张朝《苏州日记》",
       title2: "Zhangh Zhao - “Suzhou Diary”",
      content1: "作品以苏州人的生活经历为线索，展现苏州人多彩的人生与对美好生活向往的火热情怀，歌颂了当代中国的繁荣富强与中国人民走进新时代过上的幸福日子！",
      content2:"The work was inspired by the living experience of Suzhou citizens, showcasing the wonderful life and passion of living of the people, eulogizing the prosperity of China and the happy life of the Chinese people in the new era!"
    },
  ],
  "4": [
    {
      url: 'xuchangjun',
      title1: "徐昌俊—交响组曲「江南」",
      title2: "Xu Changjun - Symphonic Suite “Jiangnan”",
      content1: "《江南》采用多乐章交响组曲的体裁形式，通过叙事交响乐音画的创作手法，追求新颖、独特地域特色、可听性与创新性有机结合，将昆曲、评弹、古琴、编钟等艺术与民族管弦乐队融为一体，用音乐描绘锦绣江南。",
      content2:"In the genre of a multi-movement symphonic suite, “Jiangnan” utilizes the composition technique of a narrative symphonic tableaux, featuring unique regional characteristics and the combination of audibility and novelty. By seamlessly integrating Kunqu opera, pingtan, guqin and bianzhong into the Chinese orchestra, the suite depicts the picturesque beauty of Jiangnan (the southern Yangtze region)."
    },
    {
      url: 'liuchangyuan',
      title1: "刘长远—中国交响乐《征程·光明》",
      title2: "Liu Changyuan - Journey·Light: a Chinese symphony",
      content1: "中国交响乐《征程·光明》以史诗般的叙事和深邃的情感，描绘了中华民族在追求光明与复兴道路上的壮丽图景。多样化的情感在乐声中层层递进，从一开始的欢快跃动到复杂多变，从温柔深邃到和谐悠扬，最后激昂有力的旋律响彻全场，抒发出对真理与光明的深切呼唤，颂扬了中华民族坚韧不拔的精神品质。",
      content2:"The Chinese symphonic work Journey · Light employs an epic narrative and profound emotions to depict a magnificent panorama of the Chinese nation in pursuit of light and rejuvenation. Diverse emotions unfold progressively through the music, from the cheerful and lively at the beginning to the complex and changeful, from the gentle and profound to the harmonious and melodious. Ultimately, a powerful and stirring melody resounds throughout the house, voicing a heartfelt call for truth and light while celebrating the indomitable spirit of the Chinese people."
    },
    {
      url: 'guannaizhong',
      title1: "关乃忠—第四交响乐·第四乐章",
      title2: "Guan Naizhong - Movement from Symphony No.4",
      content1: "整个乐章充满了欢快和喜悦的气氛，引用了“步步高”的旋律。三连音、五连音在不同的声部形成了复合的节奏。闪光、跳动融合成了一片欢乐的海洋，这也许就是我们无悔青春的写照吧",
      content2:"The movement incorporates the melody of Bu Bu Gao (Ascending Step by Step) so that it is filled with cheer and joy throughout. The use of triplets and quintuplets in different parts creates a compound rhythm. Sparkling and bouncing elements merge into a sea of joy, conceivably reflecting a portrayal of us having no regrets about our youth."
    },
    {
      url: 'guannaizhong',
      title1: "关乃忠—第五中国交响乐《复兴》",
       title2: "Guan Naizhong - Chinese Symphony No.5 “Rejuvenation”",
      content1: "乐曲充分借鉴西方交响乐的创作手段，立足于中国民族音乐传统，融入中国传统哲学思想，以苏州弹词开篇为动机，引用敦煌琵琶曲为素材，串联起五个乐章，以盛唐气象为切入点，描述了一个伟大民族的荣辱悲欢，预示着中华民族的复兴强盛。",
      content2:"Rooted in Chinese traditional music, the entire composition fully utilizes the creative techniques of Western symphonic music with the incorporation of traditional Chinese philosophical ideas. Starting with an opening ballad in Suzhou pingtan as the motif, it quotes Dunhuang pipa tunes, connecting them in five movements. With the grandeur of the Tang Dynasty as the starting point, it describes the rise and fall of a great nation, heralding the resurgence and prosperity of the Chinese nation."
    },
    {
      url: 'liuchangyuan',
      title1: "刘长远—中国管弦乐《苏州好风光》",
       title2: "Liu Changyuan - Beautiful Scenery of Suzhou: a Chinese symphony",
      content1: "作品旋律变换细腻，热烈快板渐进至深情慢板，最终升华至激昂广板，精妙展现了苏州独有的风雅景致。",
      content2:"The piece features delicate melodic transitions, progressing from a lively allegro to an affectionate adagio, and ultimately soaring to an impassioned largo. It exquisitely captures the unique elegance and charm of Suzhou's scenery."
    },
    {
      url: 'gonghuahua',
      title1: "龚华华—第一中国交响乐《楚魂》",
       title2: "Gong Huahua - Chinese Symphony No.1 “The Soul of Chu”",
      content1: "作曲家龚华华以古楚国地域民间音乐作为素材，结合自己对文献中楚国之“魂”的理解，试图以当代人的视角讲述楚国的故事、刻画楚地的场景、展现楚人的精神与气质，本作品此次为世界首演。",
      content2:"The composer Gong Huahua draws on the folk music of the area of ​​ancient Chu State, combining it with his interpretation of the “soul” of Chu in historical documents. Through a contemporary lens, he seeks to tell the story of Chu, depict its landscapes, and embody the spirit and character of its people. The performance of the work this time is the world premiere."
    },
    {
      url: 'libo',
      title1: "李博—民族管弦乐《和平之光》",
       title2: "Li Bo - The Light of Peace: a Chinese symphony",
      content1: "习总书记曾在联合国日内瓦总部发表的题为《共同构建人类命运共同体》的演讲中说：中国《孙子兵法》是一部著名兵书，但其第一句话就讲：“兵者，国之大事，死生之地，存亡之道，不可不察也”，其要义是慎战、不战。几千年来，和平融入了中华民族的血脉中，刻进了中国人民的基因里。",
      content2:"In his speech Working Together to Build a Community with a Shared Future for Humanity delivered at the United Nations Office in Geneva, President Xi Jinping remarked: “The Art of War” by Sun Tzu is a renowned Chinese military book, but its very first sentence reads “Warfare is a matter of vital importance to the state. It is a matter of life and death, a road either to survival or to ruin. Hence it is a subject for careful study.” Its core principle is prudence in waging war and preference for avoiding it. For thousands of years, peace has been ingrained in the lifeblood of the Chinese nation and etched into the DNA of the Chinese people."
    },
  ],
};
const dateTitle = {
  '1' : '2018~2019 ',
  '2' : '2019~2020 ',
  '3' : '2020~2021 ',
  '4' : '2022~2024'
}
export default {
  components: { headNav, footNav },
  data() {
    return {
      id: "1",
    };
  },
  computed:{
    contents() {
      return dataSoure[this.id]
    },
    titleText(){
      return dateTitle[this.id]
    }
  },
  created() {
    this.id = this.$route.params.id || 0;
    console.log("tag", this.id);
  },
};
</script>

<style scoped lang="scss">
.production-detail {
  background-color: #f2f6f7;

  .contents {
    padding-top: 180px;
    .tips {
      margin-bottom: 80px;
    }
    .item {
      margin-bottom: 50px;
    }
    .text {
      width: 790px;
      h3 {
        padding: 24px 0 12px;
      }
      p {
        line-height: 160%;
      }
    }

    .pic {
      width: 341px;
      height: 239px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
