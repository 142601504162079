<template>
  <div class="production-list">
    <headNav active="2"></headNav>
    <div class="title w1200 flex between v-end">
      <img
        src="../assets/production/production-title.png"
        alt=""
        v-if="$i18n.locale == 'zh'"
      />
      <img src="../assets/production/production-titleEn.png" alt="" style="width: 438px;" v-else />
      <div class="text-1 cur" @click="routerPush()"
        v-if="$i18n.locale == 'zh'">
          <img
            src="../assets/music-1.png"
            alt=""
            srcset=""
            class="img-1 inbl-m"
          />
          <span class="inbl-m ml-7">租借乐谱</span>
        </div>
    </div>
    <div class="flex between w1200 pb40 wrap">
      <div class="production-item">
        <router-link :to="{ name: 'production-detail', params: { id: 1 } }">
          <img src="../assets/production/1819.png" alt="" />
        </router-link>
        <p class="fs20 pt24">2018 ～ 2019</p>
      </div>
      <div class="production-item">
        <router-link :to="{ name: 'production-detail', params: { id: 2 } }">
          <img src="../assets/production/1920.png" alt="" />
        </router-link>
        <p class="fs20 pt24">2019 ～ 2020</p>
      </div>
      <div class="production-item">
        <router-link :to="{ name: 'production-detail', params: { id: 3 } }">
          <img src="../assets/production/2021.png" alt="" />
        </router-link>
        <p class="fs20 pt24">2020 ～ 2021</p>
      </div>
      <div class="production-item">
        <router-link :to="{ name: 'production-detail', params: { id: 4 } }">
          <img src="../assets/production/2024.png" alt="" />
        </router-link>
        <p class="fs20 pt24">2022 ～ 2024</p>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  methods: {
    // 租借乐谱
    routerPush() {
        this.$router.push({ name: "LoanSheetMusic" });
    }
  },
};
</script>

<style scoped lang="scss">
.production-list {
  background-color: #f2f6f7;
  .title {
    padding: 130px 0 120px;
  }
  .text-1 {
    width: 130px;
    height: 45px;
    border: 1px solid #bf783d;
    border-radius: 4px;
    text-align: center;
    line-height: 45px;
    font-size: 16px;
    color: #bf783d;
  }
  .img-1 {
    width: 30px;
    height: 30px;
  }
}
.production-item{
  width: 30%;
  img{
    width: 300px;
    height: 300px;
  }
}
</style>